import { createStore } from 'vuex'
import axios from 'axios'
/** Parts */
import steps from './steps'
import modals from './modals'
/** Helpers */
import {/** dataApiUrl, */contactApiUrl, contentApiUrl, makeApiUrl, paymentUUIDUrl, paymentUUIDAPIKey, paymentUUIDAPIValue, paymentIframeSuccess, paymentIframeFailure, paymentIframeUrl, googleSheetUrl } from './constants'
import { getProposalIdFromUrl } from '@/inc/helpers'

export default createStore({
  state: {
    steps,
    modals,
    activeModal: null,
    userData: null,
    mainSlider: null,
    content: null,
    currentVideo: null,
    isMobile: window.innerWidth <= 990,
    currentStep: null,
    apiError: null,
    showContactMessage: false,
    currentLeasing: null,
    apiResponse: null,
    sendingForm: false,
    userName: null,
    userEmail: null,
    userPhone: null,
    userCrmID: 0,
    paymentAmount: 990,
    paymentDescription: 'מקדמה',
    paymentIframe: '',
    policyLink: '',
    formData: null,
    customerFormSent: false,
    reloadIframe: {
      value: true
    }
  },
  getters: {
    reloadIframe: (state) => state.reloadIframe,
    customerFormSent: (state) => state.customerFormSent,
    formData: (state) => state.formData,
    userData: (state) => state.userData,
    modals: ({ modals }) => modals,
    activeModal: ({ activeModal }) => activeModal,
    isMobile: (state) => state.isMobile,
    popups: ({ content }) => content && ({
      installation: content.popup_installation,
      hardware: content.popup_hardware,
      output: content.popup_output,
      environment: content.popup_environment,
      planningFlat: content.planning_flat,
      planningPitched: content.planning_pitched,
      warranty: content.warranty,
      maintenance: content.maintenance,
      contact: content.contact,
    }),
    paymentIframe: (state) => state.paymentIframe,
    policyLink: (state) => state.policyLink,
    content: (state) => state.content,
    activeModalContent: (state, getters) => getters.popups[getters.activeModal.name],
    currentVideo: (state) => state.currentVideo,
    hasMixedRoof: state => state.userData.ProposalOutput.RoofType === 'Tiles And Flat',
    activeStepName: state => state.steps.find(step => step.active)?.name,
    activeStepIndex: state => state.steps.find(step => step.active)?.step,
    apiResponse: state => state.apiResponse,
    sendingForm: state => state.sendingForm,
  },
  mutations: {
    triggerIframeReload(state) {
      state.reloadIframe = { value: true };
    },
    resetIframeReload(state) {
      state.reloadIframe = { value: false };
    },
    setCustomerFormSent(state, data) {
      state.customerFormSent = data;
    },
    setSendingForm(state, data) {
      state.sendingForm = data ? true : false;
    },
    setApiResponse(state, data) {
      console.log('User ID received from make API:', data);
      state.apiResponse = data ? true : false;
      state.userCrmID = data ? data : 0;

      this.dispatch({
        type: "postPaymentUUID",
      });
    },
    setStep(state, index) {
      const active = state.steps.find(el => el.active)
      if (index === 3) {
        state.mainSlider && state.mainSlider.slideTo(2);
        state.steps.find(el => el.active).active = false;
        state.steps.find(el => el.step === index).active = true
        window.history.replaceState(null, null, '#' + index);
        return;
      }

      if (active.step === index || !state.steps.find(el => el.step === index).canAccess) return;

      state.steps.find(el => el.active).active = false
      state.steps.find(el => el.step === index).active = true
      state.currentStep = state.steps.find(el => el.step === index)
      state.mainSlider && state.mainSlider.slideTo(index);

      document.querySelector('.swiper-button-next').classList.add('stepped')

      window.history.replaceState(null, null, '#' + index)
    },
    setUserData(state, data) {
      console.log(data);
      state.userData = data
    },
    setFormData(state, data) {
      let filteredFormData = {};
      let emailBody = '';
      for (let [key, value] of Object.entries(data)) {
        if (value) {
          filteredFormData[key] = value;
          if (key !== 'EmailBody') emailBody += `${key} - ${value}\r `;
        }
      }
      filteredFormData.EmailBody = emailBody;
      state.formData = filteredFormData
    },
    setCRMUserData(state, data) {
      state.userName = data.Name;
      state.userEmail = data.Email;
      state.userPhone = data.Phone;
    },
    setContent(state, data) {
      state.content = data
    },
    setPaymentIframeUrl(state, data) {
      state.paymentIframe = data;
    },
    setPaymentUUID(state, data) {
      state.paymentIframe = paymentIframeUrl + data;
      console.log('Payment API UUID received:', data);
    },
    setPolicyLink(state, data) {
      state.policyLink = data;
    },
    setModal(state, payload) {
      const name = typeof payload === 'string' ? payload : payload.type
      if (name === 'video')
        state.currentVideo = payload.url

      let activeModal = state.modals.find(modal => modal.active)
      if (activeModal) activeModal.active = false

      activeModal = state.modals.find(modal => modal.name === name)
      activeModal.active = true
      state.activeModal = activeModal

      document.body.classList.add("modal-open");
      if (state.isMobile) {
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}`;
      }

    },
    closeModal(state) {
      const active = state.modals.find(modal => modal.active)
      if (active) active.active = false
      state.currentVideo = ''
      state.activeModal = null

      document.body.classList.remove("modal-open");
      if (state.isMobile) {
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    },
    setIsMobile(state, url) {
      state.currentVideo = url
      state.isMobile = window.innerWidth <= 990
    },
    apiError(state, type) {
      state.apiError = { type }
    },
    contactRequestSuccess(state) {
      state.showContactMessage = true
    },
    setCurrentLeasing(state, selectedProgram) {
      state.currentLeasing = state.userData.LeasingSteps.find(el => el.Step == selectedProgram.step && el.Delay == selectedProgram.delay)
    }

  },
  actions: {
    triggerReload({ commit }) {
      commit('triggerIframeReload');
    },
    resetReload({ commit }) {
      commit('resetIframeReload');
    },
    // For Async
    fetchPolicyLink({ commit }) {
      axios.get(googleSheetUrl)
        .then(res => {
          commit('setPolicyLink', res.data[27][0])
        })
    },
    /////////////////////
    fetchUserData({ commit }) {
      axios.get('data.json')
        .then(res => {
          commit('setUserData', res.data)
        })
      // axios.get(dataApiUrl, {
      //   params: { ProposalID: proposalId }
      // })
      //   .then((res) => {
      //     console.log('RES', res)
      //     if (!res?.data?.DataForCustomer?.length) {
      //       throw new Error('No User Data')
      //     }
      //     console.log('Remote Data', res)
      //     commit('setUserData', res.data)
      //   })
      //   .catch((err) => {
      //     console.error(err)
      //     // axios.get('data.json')
      //     //   .then(res => {
      //     //     commit('setUserData', res.data)
      //     //   })

      //     commit('apiError', 'user')
      //   });

    },
    fetchContent({ commit }) {
      axios.get(contentApiUrl)
        .then(res => {
          if (!res || !res.data) {
            throw Error('No Content')
          } else {
            commit('setContent', res.data)
            // console.log('Content', res.data)
          }

        })
        .catch((err) => {
          console.error(err)
          commit('apiError', 'content')
        });
    },
    sendContactRequest({ commit }) {
      const proposalId = getProposalIdFromUrl()
      axios.get(contactApiUrl, {
        params: { ProposalID: proposalId }
      })
        .then(res => {
          // console.log('res', res)
          if (!res) {
            throw Error('Default ERROR')
          } else {
            commit('contactRequestSuccess')
          }
        })
        .catch((err) => {
          console.error(err)
          commit('apiError', 'content')
        });
    },
    postCustomerForm(context, { type }) {
      context.commit('setSendingForm', true);
      type, makeApiUrl;
      const formData = context.state.formData;
      context.commit('setCRMUserData', formData);
      const headers = { 'Content-Type': 'application/json' }

      console.log('All customer data:', formData);
      console.log('Calling make API...');
      fetch(makeApiUrl, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers,
        keepalive: true // Keep the request alive after the page unloads
      })
        .then(res => res.status === 200 && res.json())
        .then(res => {
          context.commit('setSendingForm', false);
          context.commit('setCustomerFormSent', true);
          if (res) {
            return context.commit('setApiResponse', res);
          }
          else context.commit('setApiResponse', false);
        })
        .catch((err) => {
          context.commit('setSendingForm', false)
          context.commit('setApiResponse', false);
          console.error(err)
        });

      // axios.post(makeApiUrl, { ...formData }, headers)
      //   .then(res => {
      //     context.commit('setSendingForm', false);
      //     context.commit('setCustomerFormSent', true);
      //     if (res.status === 200 && res.data) {
      //       console.log('api received request')
      //       return context.commit('setApiResponse', res.data);
      //     }
      //     else context.commit('setApiResponse', false);
      //   })
      //   .catch((err) => {
      //     context.commit('setSendingForm', false)
      //     context.commit('setApiResponse', false);
      //     console.error(err)
      //   });
    },
    postPaymentUUID(context) {
      const config = {
        method: 'post',
        url: paymentUUIDUrl,
        headers: {
          [paymentUUIDAPIKey]: paymentUUIDAPIValue,
          'Content-Type': 'application/json'
        },
        data: {
          PaymentAmount: context.state.paymentAmount,
          FullName: context.state.userName,
          Phone: context.state.userPhone,
          Email: context.state.userEmail,
          Description: context.state.paymentDescription,
          IsBitPayment: false,
          PaymentsNum: 1,
          IsMaxPayment: false,
          IsVerificationRequired: false,
          IsIdentificationRequired: false,
          CustomField1: context.state.userCrmID,
          Customfield2: "",
          SuccessUrl: paymentIframeSuccess,
          FailureUrl: paymentIframeFailure,
        },
      };

      console.log('Payment data before sending to payment API:', config.data);
      console.log('Calling payment API...');
      axios(config)
        .then(response => {
          context.commit('setPaymentUUID', response.data)
        })
        .catch(error => {
          console.error(error);
        });

      // const headers = {
      //   'Apikey': 'caddd3d6-1eea-4cab-ae6d-63fb665aee3f',
      //   'Content-Type': 'application/json'
      // };
      // console.log('paymentUUIDUrl:', paymentUUIDUrl);
      // console.log('headers:', headers);
      // const body = {
      //   "PaymentAmount": 5.0,
      //   "FullName": "Test",
      //   "Phone": "0559988124",
      //   "Email": "test@gmail.com",
      //   "Description": "וולטה - תשלום מקדמה",
      //   "IsBitPayment": false,
      //   "PaymentsNum": 1,
      //   "IsMaxPayment": false,
      //   "IsVerificationRequired": false,
      //   "IsIdentificationRequired": false,
      //   "CustomField1": "",
      //   "Customfield2": "",
      //   "SuccessUrl": "https://sc.volta.netrise.co.il/paymentPages/success.html",
      //   "FailureUrl": "https://sc.volta.netrise.co.il/paymentPages/failure.html"
      // }
      // const body = {
      //   PaymentAmount: context.state.paymentAmount,
      //   FullName: context.state.userName,
      //   Phone: context.state.userPhone,
      //   Email: context.state.userEmail,
      //   Description: context.state.paymentDescription,
      //   IsBitPayment: false,
      //   PaymentsNum: 1,
      //   IsMaxPayment: false,
      //   IsVerificationRequired: false,
      //   IsIdentificationRequired: false,
      //   CustomField1: context.state.userCrmID,
      //   Customfield2: "",
      //   SuccessUrl: paymentIframeSuccess,
      //   FailureUrl: paymentIframeFailure,
      // }
      // console.log('body:', body);

      // axios.post(paymentUUIDUrl, { ...body }, headers)
      //   .then(res => {
      //     if (!res || !res.data) {
      //       throw Error('no UUID')
      //     } else {
      //       context.commit('setPaymentUUID', res.data)
      //       console.log('post paymentuuid response:', res.data)
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err)
      //     context.commit('apiError', 'content')
      //   });
    },
  },
  modules: {
  },

})