<template>
    <div class="api-error">
        <div class="api-error__inner">
            <h1>{{ msg }}</h1>
        </div>
    </div>
</template>
<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
    data: Object
})
const msg = computed(() => {

    switch (props.data.type) {
        case 'user':
            return 'לקוח לא זוהה, אנא פנה לנציג וולטה'
        case 'content':
            return 'בעיית שרת תוכן, אנא נסה שנית מאוחר יותר'
        default:
            return 'אירעה שגיאה'
    }
})

</script>
<style lang="scss" scoped>
@import '@/scss/setup.scss';

.api-error {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    text-align: center;
    height: 100vh;
    width: 100%;


    &__inner {
        background-color: $dark-bg;
        border-radius: 10px;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50%;
        padding: 30px;

        h1 {
            font-size: 24px;
        }
    }
}
</style>