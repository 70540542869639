<template>
  <div class="container">
    <transition>
      <slot></slot>
    </transition>
  </div>
</template>
<script>
export default {
  name: "ContentContainer",
  setup() { },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.container {
  position: relative;
  background-color: $light-bg;
  background-color: transparent;
  height: 100%;

  @include break($small) {
    padding-bottom: 30px;
  }
}
</style>