<template>
    <div class="wrapper contact">
        <h3 class="modal-title">{{ content?.title }}</h3>
        <ul class="list">
            <li class="item wtsp">
                <a :href="`https://wa.me/${Phone}`" class="" target="_blank"  @click="() => setBtn('Whatsapp')">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.00390625 24.9985C0.0870064 24.7565 0.157121 24.5378 0.232431 24.3191C0.853951 22.4866 1.47893 20.6541 2.10738 18.8216C2.11847 18.792 2.12214 18.7602 2.11807 18.7288C2.11399 18.6975 2.10229 18.6677 2.08401 18.642C1.31259 17.3681 0.804776 15.9517 0.590801 14.4771C0.0818122 10.9813 0.954364 7.84982 3.23442 5.14267C4.49764 3.65997 6.10715 2.51352 7.91954 1.80547C9.73193 1.09742 11.6909 0.84976 13.6219 1.08456C16.3689 1.39203 18.916 2.67233 20.8049 4.69495C22.8126 6.80325 23.9542 9.5923 24.0023 12.5065C24.0504 15.4207 23.0016 18.2461 21.0646 20.4198C19.2945 22.404 16.9109 23.735 14.2961 24.1995C11.6813 24.6639 8.98668 24.2349 6.64413 22.9812C6.61162 22.9628 6.57554 22.9517 6.53834 22.9485C6.50114 22.9454 6.46371 22.9503 6.42859 22.963L0.102587 24.9725L0.00390625 24.9985ZM2.96695 22.1014L3.03966 22.0779C4.23595 21.6962 5.43312 21.3135 6.63114 20.93C6.67332 20.9117 6.71957 20.905 6.7652 20.9105C6.81082 20.916 6.85417 20.9335 6.89083 20.9612C8.43748 21.9501 10.228 22.4885 12.0623 22.5163C13.8966 22.5441 15.7024 22.0601 17.2782 21.1185C18.8539 20.1768 20.1374 18.8146 20.9854 17.184C21.8333 15.5534 22.2123 13.7186 22.08 11.8845C21.9912 10.8058 21.7217 9.74978 21.2827 8.76087C20.5755 7.16381 19.4596 5.78258 18.0481 4.75722C16.6366 3.73186 14.9797 3.09882 13.2454 2.92229C11.5702 2.74492 9.87758 2.9984 8.32726 3.65879C6.77695 4.31919 5.42011 5.36472 4.38484 6.69668C3.57037 7.72172 2.97488 8.90369 2.63536 10.1692C2.29584 11.4346 2.21955 12.7566 2.41121 14.0528C2.61935 15.614 3.2149 17.0979 4.14333 18.3686C4.16394 18.3948 4.17702 18.426 4.18114 18.4591C4.18526 18.4921 4.18027 18.5257 4.1667 18.5561C4.03167 18.9491 3.90702 19.337 3.76678 19.7378C3.5019 20.5187 3.23702 21.2996 2.96695 22.1014Z"
                            fill="#FDFEFD" />
                        <path
                            d="M6.00402 9.78726C5.99855 9.31682 6.09458 8.85078 6.28551 8.42125C6.47644 7.99171 6.75772 7.60888 7.10997 7.29913C7.23923 7.18105 7.39358 7.09416 7.56127 7.04507C7.72896 6.99598 7.90557 6.98598 8.07769 7.01584C8.22183 7.02918 8.36688 7.02918 8.51103 7.01584C8.58549 7.0104 8.66003 7.02514 8.7269 7.05851C8.79377 7.09188 8.8505 7.14267 8.8912 7.20559C8.97001 7.31814 9.03433 7.44026 9.08262 7.56905C9.38569 8.29331 9.68877 9.02024 9.9945 9.7445C10.0299 9.8211 10.0483 9.90456 10.0483 9.98903C10.0483 10.0735 10.0299 10.157 9.9945 10.2336C9.79979 10.6089 9.54365 10.9486 9.23681 11.2384C9.01084 11.4897 8.98691 11.6099 9.15971 11.8959C10.0261 13.4004 11.3612 14.5763 12.9588 15.2419C13.0678 15.2873 13.1715 15.3381 13.2805 15.3809C13.3615 15.4173 13.4523 15.4255 13.5385 15.4042C13.6247 15.3829 13.7014 15.3333 13.7563 15.2633C14.078 14.8918 14.3997 14.523 14.6868 14.1275C14.7123 14.0711 14.7492 14.0208 14.7952 13.9797C14.8413 13.9387 14.8954 13.9078 14.954 13.8892C15.0127 13.8705 15.0746 13.8645 15.1358 13.8715C15.1969 13.8785 15.2559 13.8983 15.3089 13.9297C15.6386 14.05 15.9523 14.1969 16.2687 14.3626C16.7578 14.5978 17.2417 14.841 17.7335 15.0735C17.8202 15.1086 17.8931 15.1711 17.9411 15.2517C17.9892 15.3323 18.0096 15.4265 17.9994 15.5198C17.9864 15.9283 17.9036 16.3315 17.7548 16.7118C17.7045 16.8357 17.6314 16.949 17.5395 17.0459C17.1068 17.5271 16.5413 17.8676 15.9151 18.024C15.4424 18.1673 14.9402 18.1802 14.4609 18.0614C14.1631 17.9786 13.8653 17.9037 13.5729 17.7942C12.7928 17.5544 12.0357 17.2449 11.3105 16.8695C10.4084 16.3561 9.58684 15.7112 8.87259 14.9559C8.00205 14.0541 7.24409 13.0489 6.61548 11.9627C6.28985 11.4151 6.08158 10.8053 6.00402 10.1721C6.00126 10.1446 6.00126 10.1168 6.00402 10.0893V9.78726Z"
                            fill="#FDFEFD" />
                    </svg>
                    {{ Name }}, {{ Role }}</a>
            </li>
            <li class="item"><a :href="`mailto:${Email}`" @click="() => setBtn('Email')">
                    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.8645 27.7454C16.4644 27.7454 16.0643 27.7454 15.7213 27.6372C10.7484 26.9886 7.49036 22.0156 8.34775 16.5562C8.74786 13.9616 10.0054 11.6372 11.9488 10.0156C13.9494 8.33995 16.3501 7.58319 18.8079 7.90751C23.7236 8.61022 27.0388 13.5832 26.1814 19.0426C25.7813 21.6372 24.5238 23.9616 22.5804 25.5832C20.8656 26.9886 18.8651 27.7454 16.8645 27.7454ZM17.6076 10.7724C16.3501 10.7724 15.0354 11.2589 13.9494 12.1778C12.5775 13.367 11.663 15.0426 11.3772 16.9345C10.7484 20.7724 12.9205 24.2859 16.1214 24.7183C17.6076 24.9345 19.1509 24.4481 20.4655 23.367C21.8373 22.1778 22.7519 20.5021 23.0377 18.6102C23.6664 14.7724 21.4944 11.2589 18.2935 10.8264C18.0648 10.8264 17.8362 10.7724 17.6076 10.7724Z"
                            fill="#00358A" />
                        <path
                            d="M19.034 36.5C8.51673 36.5 0 28.3919 0 18.5C0 8.60811 8.51673 0.5 19.034 0.5C29.6085 0.5 34.2956 7.63514 35.4388 11.7973C36.5248 16.0135 36.4676 24.8784 31.2661 26.1757C28.8654 26.7703 27.265 26.0135 26.3504 25.3108C22.9209 22.5541 23.0352 15.2027 23.6639 9.52703C23.7783 8.71622 24.5213 8.17568 25.3787 8.22973C26.2361 8.33784 26.8077 9.04054 26.7505 9.85135C25.836 17.8514 26.9792 21.9595 28.351 23.0946C28.694 23.3649 29.2656 23.6892 30.4659 23.3649C32.9809 22.7162 33.4953 16.3378 32.4665 12.5C32.1807 11.5811 29.7228 3.41892 19.034 3.41892C10.2315 3.41892 3.0866 10.1757 3.0866 18.5C3.0866 26.8243 10.2315 33.5811 19.034 33.5811C19.8914 33.5811 20.5773 34.2297 20.5773 35.0405C20.5773 35.8514 19.8914 36.5 19.034 36.5Z"
                            fill="#00358A" />
                    </svg>
                    {{ Email }}</a></li>
            <li class="item phone"><a :href="`tel:${Phone}`" @click="() => setBtn('Phone')">
                    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.14104 36.0601C8.58368 36.8317 9.89895 36.5349 11.2769 35.5853C22.488 28.0477 30.0664 19.8573 35.6407 9.29277C36.3296 7.98704 35.9538 6.80002 35.1396 5.31625C34.0122 3.29831 31.3191 1.16167 31.3191 1.16167C29.5654 -0.203402 27.3733 0.686864 26.2459 2.52675C24.868 4.60403 23.8032 7.21548 23.3022 8.4025C22.9264 9.35212 23.2395 10.4204 24.0538 11.0733L25.369 12.1416C26.3085 12.9132 26.6217 14.2783 25.8701 15.2279C22.1748 20.2134 19.2311 23.6557 14.0327 27.0981C13.0305 27.7509 11.6526 27.8103 10.7132 27.0387L9.52316 26.0891C8.70894 25.4363 7.58157 25.3176 6.64209 25.7924C5.63998 26.2672 3.69839 27.9884 2.88418 28.7599C1.5689 29.9469 -1.1869 32.1429 0.566794 33.5674C0.566794 33.5674 1.5689 34.3983 4.26208 35.4666"
                            fill="#00358A" />
                    </svg>
                    {{ phoneNumberFormatted }}</a></li>
            <li class="item"> <button :class="{ btn: 1, ['btn--secondary']: 1, sending }" @click="sendContactRequest"
                    :disabled="showMsg">{{ content?.button_text ?? 'דברו איתי!' }}</button>
            </li>
            <li class="item" v-if="showMsg">
                <p class="msg">{{ content?.success_message }}</p>
            </li>
        </ul>
    </div>
</template>
<script setup>
import { formatPhone, createDataLayerEvent } from '@/inc/helpers'
import { computed, ref, toRef, watch } from '@vue/runtime-core';
const { useStore } = require("vuex");

/** User data */
const store = useStore()
const expert = store.getters.userData.SolarExpertDetails
const { Name, Email, Phone, Role } = expert
const phoneNumberFormatted = formatPhone(Phone)
const showMsg = toRef(store.state, 'showContactMessage')


/** Content */
const content = computed(() => store.getters.activeModalContent)
const sending = ref(false)

const sendContactRequest = () => {
        createDataLayerEvent({
          'event': 'contact_us',
          'contact_medium': 'Contact Us Form',
          'button_location': 'Contact Us Pop Up'
        });
    sending.value = true
    store.dispatch('sendContactRequest')
}

const setBtn = (btn) => {
        createDataLayerEvent({
          'event': 'contact_us',
          'contact_medium': btn,
          'button_location': 'Contact Us Pop Up'
        });
}

watch(showMsg, () => sending.value = false)



</script>
<style lang="scss" scoped>
@import '@/scss/setup.scss';

.contact {
    padding: 56px 56px 36px;

    @include break($small ) {
        padding: 8vh 8vw 4vh;
    }
}

.modal-title {
    font-size: 34px;

    @include break($small ) {
        margin-bottom: 20px;
    }
}

.item {
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 24px;


    @include break($small ) {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .btn {
        font-size: 15px;
        padding: 0 36px;
        height: 36px;
    }

    a:not(.btn) {
        display: flex;
        align-items: center;
    }

    svg {
        border-radius: 100%;
        height: 36px;
        width: 36px;
        display: block;
        margin-left: 28px;
        flex-shrink: 0;

        @include break($small ) {
            margin-left: 16px;
        }
    }

    &.wtsp svg {
        background: #2fbe39;
        padding: 5px 5px 6px 6px;
        overflow: visible;

    }


    span {
        font-size: vw(40);
        margin-left: vw(8);
        line-height: 1;

        @include break($small ) {
            font-size: 30px;
            margin-left: 8px;
        }
    }
}

.msg {
    color: #2fbd39;
    font-size: 24px;
}

.sending {
    position: relative;
    opacity: 1 !important;

    &::after {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(#fff, .85);
        display: block;
        z-index: 1;
    }

    &::before {
        content: '';
        height: 20px;
        width: 20px;
        border: 2px solid transparent;
        border-top-color: $primary;
        border-radius: 50%;
        position: absolute;
        left: 4px;
        animation: rotate 1.2s cubic-bezier(0.39, 0.58, 0.57, 1) infinite;
        z-index: 20;
    }
}
</style>