<template>
  <main class="main intro">
    <div class="main__inner">
      <div class="layout">
        <h1 class="main-title">
          מתקדמים לאנרגיה סולארית בקליק.
          <br>
        </h1>
        <button @click="nextSlide" type="button" class="intro__lets-start"><span>מוכנים? בואו נתחיל!</span></button>
      </div>
    </div>
    <div class="lottie-wrapper">
      <lottie-animation class="anim1" v-if="isMobile" ref="animMob" :animationData="mobileAnimation" />
      <lottie-animation v-else class="anim2" ref="animDesk" :animationData="desktopAnimation" />
    </div>
    <div v-if="!isMobile" class="credit">
      created by:
      <a href="https://netrise.co.il/" target="_blank">Netrise.co.il</a>
    </div>
  </main>
</template>

<script>
// Core
import {
  ref,
  toRef,
  watch,
  nextTick,
} from "vue";
import { useStore } from "vuex";

import { createDataLayerEvent } from '@/inc/helpers'


export default {
  name: "ViewIntro",
  props: {},
  components: {},
  setup() {
    const animMob = ref(null)
    const animDesk = ref(null)
    const store = useStore();
    const isMobile = toRef(store.getters, "isMobile");
    const startAnimation = ref(true);
    const mobileAnimation = require("@/assets/house_mobile.json");
    const desktopAnimation = require("@/assets/lottie/desktop_house.json");
    const activeBtnWidth = ref(0)
    const btns = ref({ phone: false, email: false });

    function setBtn(btn, e = null) {
      if (btn === "Phone") {
        btns.value.phone = !btns.value.phone;
        btns.value.email = false;
      } else {
        btns.value.phone = false;
        btns.value.email = !btns.value.email;
      }
      if (e) {
        nextTick(() => {
          const width = window.document.querySelector('.icon.active .icon__link')?.scrollWidth
          if (width && !isMobile.value)
            activeBtnWidth.value = width + 150 + 'px'
          if (width && isMobile.value)
            activeBtnWidth.value = width + 65 + 'px'
        })
      }
    }

    function nextSlide() {
      store.commit("setStep", 1);
    }

    function sendDataLayerEvent(btn) {
      createDataLayerEvent({
        'event': 'contact_us',
        'contact_medium': btn,
        'button_location': 'Main Page'
      });
    }
    watch(
      () => store.state.currentStep,
      () => {
        startAnimation.value =
          store.state.steps.find((step) => step.active).component?.name ===
          "ViewIntro";

        const anim = animMob.value || animDesk.value;
        // if (anim) startAnimation.value ? anim.play() : anim.stop();
        if (anim) anim.play();
      }
    );

    return {
      btns,
      setBtn,
      isMobile,
      mobileAnimation,
      desktopAnimation,
      startAnimation,
      activeBtnWidth,
      animMob,
      animDesk,
      nextSlide,
      sendDataLayerEvent
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/setup";

.intro {
  background-color: $light-bg;

  @include break($small) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }

  .main__inner {
    z-index: 2;
    position: relative;
  }
}

.subtitle {
  font-size: vw(38, 1920);
  font-weight: bold;

  &:first-of-type {
    margin-top: vw(44, 1920);
  }

  @include break($large) {
    font-size: vw(34);

    &:first-of-type {
      margin-top: vw(44);
    }
  }

  @include break($small) {
    font-size: 6vw;
    font-weight: normal;
  }
}

.contact-text {
  font-size: vw(34, 1920);
  margin-top: vw(50, 1920);
  font-weight: 300;
  margin-bottom: vw(25, 1920);

  @include break($large) {
    font-size: vw(26);
    margin-top: vw(60);
    margin-bottom: vw(25);
  }

  @include break($small) {
    font-size: 21px;
    font-size: 5vw;
    font-weight: 300;
    margin-top: 40px;
    margin-bottom: 32px;
  }
}

.layout {
  width: 100%;
  height: 100%;
}

.main-title {
  padding-top: 5vh;

  @include break($large) {
    width: 45%;
    padding-top: 12vh;
  }

  @include break($medium) {
    width: 45%;
    padding-top: 5vh;
  }

  @include break($small) {
    width: 100%;
    padding-top: 2vh;
    font-size: 36px;
  }
}

.icons {
  display: flex;
  align-items: center;


  .icon {
    overflow: hidden;
    background-color: $secondary;
    height: vw(90, 1920);
    width: vw(90, 1920);
    overflow: hidden;
    border-radius: vw(45, 1920);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: width 230ms ease-out, background-color 180ms ease-out;
    margin-left: vw(12);


    @include break($large) {
      height: vw(90);
      width: vw(90);
      border-radius: vw(45);
    }

    @include break($small) {
      height: 20vw;
      width: 20vw;
      height: 45px;
      width: 45px;
      border-radius: 100px;
      margin-left: 2vw;
    }

    &.active {
      /*  width: vw(350, 1920);

      @include break($large) {
        width: vw(350);
      }
      @include break($small) {
        width: 180px;
      }
      */
    }

    &--wa {
      background-color: #2fbe39;

      &:hover {
        background-color: darken(#2fbe39, 4%);
      }
    }

    &--primary {
      background-color: $primary;

      &:hover {
        background-color: darken($primary, 4%);
      }
    }

    &--secondary {
      background-color: $secondary;

      &:hover {
        background-color: darken($secondary, 4%);
      }
    }

    &__link {
      white-space: nowrap;
      margin: 0 vw(20, 1920);
      font-size: vw(30, 1920);
      color: #fff;
      direction: ltr;

      @include break($large) {
        margin: 0 vw(20);
        font-size: vw(30);
      }

      @include break($small) {
        font-size: 18px;
        margin: 0;
      }

      &--email {
        @include break($small) {
          font-size: 15px;
        }
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 vw(90, 1920);

      @include break($large) {
        flex: 0 0 vw(90);
      }

      @include break($small) {
        flex: 0 0 45px;
      }

      &--wa {
        svg {
          width: 90px;
          height: 90px;
          max-width: 100%;
        }
      }
    }
  }

  svg {
    height: 58px;
    width: auto;
    max-width: 70%;
  }
}

.lottie-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  direction: ltr;
  z-index: 0;
  max-height: 115%;

  @include break($small) {
    position: static;
  }

  >div {
    width: 100vw;

    @include break($large) {
      height: auto;
    }
  }
}

.credit {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  z-index: 20;
  font-size: 15px;
}
</style>
<style lang="scss">
.lottie-wrapper {
  svg {
    display: block;
  }
}

.intro__lets-start {
  margin-block: 3vh;
  padding: 18px 46px 18px 62px;
  background: url(../assets/arrow-left-primary.svg) center left 30px no-repeat, #f7be2f;
  background-size: 15px, 100%;
  width: auto;
  border: none;
  border-radius: 2px;
  font-size: 1.3vw;
  font-weight: 700;
  line-height: 26px;
  color: #00358a;
}

@media screen and (max-width: 990px) {
  .layout {
    padding-top: 20px;
  }

  .intro__lets-start {
    margin-block: 4vh;
    padding: 16px 40px 16px 57px;
    width: 100%;
    font-size: 20px;
  }
}
</style>
