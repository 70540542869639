import { shallowRef } from 'vue';
import { v4 as uid } from 'uuid';

import ViewIntro from "../views/Intro.vue";
import ViewCustomer from '@/views/Customer.vue';
import ViewCalculator from '../views/Calculator.vue';
import ViewPayment from '../views/Payment.vue';

export default [
  {
    step: 0,
    text: 'עמוד הבית',
    active: true,
    component: shallowRef(ViewIntro),
    id: uid(),
    gtm_url: '/',
    gtm_title: 'הצעת מחיר - עמוד הבית',
    canAccess: true,
  },
  {
    step: 1,
    text: 'פרטי לקוח',
    active: false,
    component: shallowRef(ViewCustomer),
    id: uid(),
    gtm_url: '/user-info',
    gtm_title: 'הצעת מחיר - פרטי לקוח',
    canAccess: true
  },
  {
    step: 2,
    text: 'בניית מערכת',
    active: false,
    component: shallowRef(ViewCalculator),
    id: uid(),
    gtm_url: '/about-us',
    gtm_title: 'הצעת מחיר - מי אנחנו',
    canAccess: false
  },
  {
    step: 3,
    text: 'מחיר',
    active: false,
    component: null,
    id: uid(),
    gtm_url: '/pricing-quote',
    gtm_title: 'הצעת מחיר - הסכם',
    canAccess: false
  },
  {
    step: 4,
    text: 'תשלום',
    active: false,
    component: shallowRef(ViewPayment),
    id: uid(),
    gtm_url: '/payment',
    gtm_title: 'תשלום',
    canAccess: false
  },
]