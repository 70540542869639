<template>
  <div :class="{ wrapper: 1, switching }">
    <div class="col col--first">
      <button v-if="hasMixedRoof && activeModal.name === 'installation'" class="btn btn--secondary toggle-content"
        @click="toggleContent">{{
            toggleContentBtn
        }}</button>
      <h3 class="modal-title">{{ content.title }}</h3>
      <div class="running-text" v-html="content.text"></div>
      <p v-if="content.note" class="running-text note">{{ content.note }}</p>
    </div>
    <div class="col col--second">
      <video v-if="content.is_video" :src="content.video.url" autoplay :loop="activeModal.name != 'output'"
        playsinline></video>
      <img v-else :src="content.image.url" alt="" :class="{ fit: activeModal.name == 'output' }">
    </div>
  </div>
</template>
<script>
// Core
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";

export default {
  name: "TextMedia",
  props: {},
  setup(props) {
    const store = useStore();
    const activeModal = ref(store.getters.activeModal)
    const { RoofType: roofType } = store.getters.userData.ProposalOutput
    const { hasMixedRoof } = store.getters
    const currentRoofType = ref(null)
    const rawContent = store.getters.activeModalContent
    const content = ref(store.getters.activeModalContent)
    const toggleContentBtn = ref('לגג שטוח')
    const switching = ref(false)


    console.log('RAW', activeModal.value)

    if (activeModal.value.name === "installation") {

      if (roofType === 'Flat') {
        content.value = rawContent.flat_roof_popup
        currentRoofType.value = 'flat'
      } else {
        content.value = rawContent.pitched_roof_popup
        currentRoofType.value = 'tiles'
      }
    }

    /* for installation, switch roof types */
    function toggleContent() {
      if (!hasMixedRoof) return

      switching.value = true
      setTimeout(() => {
        if (currentRoofType.value === 'flat') {
          content.value = rawContent.pitched_roof_popup
          toggleContentBtn.value = 'לגג שטוח'
          currentRoofType.value = 'tiles'
        } else {
          content.value = rawContent.flat_roof_popup
          toggleContentBtn.value = 'לגג רעפים'
          currentRoofType.value = 'flat'
        }
        switching.value = false
      }, 360);


    }

    //let { title, text, image, video, is_video, note } = toRefs(content.value);
    return { ...props, content, toggleContent, hasMixedRoof, toggleContentBtn, switching, activeModal };
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/setup";

.wrapper {
  display: flex;
  width: 1020px;
  justify-content: space-between;
  //overflow: hidden;
  transition: opacity 360ms linear;

  @include break($small ) {
    display: block;
    width: 100%;

  }
}


.col {
  position: relative;

  &--first {
    padding: 60px 58px;
    flex: 0 0 460px;
    //min-height: 560px;

    @include break($large) {
      flex: 1 0 47%;
      //min-height: 430px;
    }

    @include break($small ) {
      padding: 55px 16px 16px;
      min-height: auto;
    }
  }

  &--second {
    overflow-y: hidden;

    @include break($large) {
      max-height: 85vh;
      max-width: 85vh;
    }

    @include break($small) {
      border-radius: 0 0 22px 22px;
      overflow: hidden;
    }

  }

  .note {
    font-size: 15px;

    @include break($small) {
      font-size: 13px;
    }
  }

  img,
  video {
    display: block;
    height: 101%;
    width: 560px;
    max-width: 100%;
    object-fit: cover;

    @include break($small) {
      width: 100%;
      // height: auto;
    }

    &.fit {
      object-fit: unset;
    }
  }
}

.switching {
  opacity: 0;
}

.toggle-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;
  font-size: 15px;
  height: 40px;
  min-width: 120px;

  @include break($small ) {
    bottom: unset;
    top: 16px;
    left: 16px;
    transform: none;
    font-size: 13px;
    height: 34px;
    min-width: 90px;
  }
}
</style>