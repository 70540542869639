<template>
  <div class="video-wrapper">
    <video class="video" controls="true" :src="url" autoplay="true" playsinline @play="(e) => videoPlay(e)" @pause="(e) => videoPause(e)" @timeupdate="(e) => videoTimeupdate(e)" @volumechange="(e) => videoVolumechange(e)"></video>
  </div>
</template>
<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import { createDataLayerEvent } from '@/inc/helpers'
export default {
  name: "VideoModal",
  setup() {
    const store = useStore();
    const url = computed(() => store.getters.currentVideo);
    const isMobile = store.getters.isMobile

    function videoPlay(e) {
      const video = e.target;
      var minutes = parseInt(video.duration / 60, 10);
      var seconds = parseInt(video.duration % 60);

      createDataLayerEvent({
        'event': 'video',
        'video_name': video.src,
        'video_event': video.currentTime < 0.5 ? 'Start Playing' : 'Resume Playing',
        'video_length': minutes + ':' + seconds
      })
    }
    function videoPause(e) {
      const video = e.target;
      var minutes = parseInt(video.duration / 60, 10);
      var seconds = parseInt(video.duration % 60);

      createDataLayerEvent({
        'event': 'video',
        'video_name': video.src,
        'video_event': 'Pause',
        'video_length': minutes + ':' + seconds
      })
    }

    let timeUpdateReported = {
      5 : false,
      10 : false,
      25 : false,
      50 : false,
      75 : false,
      100 : false
  };

    function videoTimeupdate(e) {
      const video = e.target;
      var minutes = parseInt(video.duration / 60, 10);
      var seconds = parseInt(video.duration % 60);
      const percentage = parseInt(video.currentTime / video.duration * 100);
      
      let finalPercentageToReport = false;

      if(percentage >= 4 && percentage <= 6 && ! timeUpdateReported[5]) {
        timeUpdateReported[5] = true;
        finalPercentageToReport = 5;
      }

      if(percentage >= 9 && percentage <= 11 && ! timeUpdateReported[10]) {
        timeUpdateReported[10] = true;
        finalPercentageToReport = 10;
      }

      if(percentage >= 23 && percentage <= 27 && ! timeUpdateReported[25]) {
        timeUpdateReported[25] = true;
        finalPercentageToReport = 25;
      }

      if(percentage >= 48 && percentage <= 52 && ! timeUpdateReported[50]) {
        timeUpdateReported[50] = true;
        finalPercentageToReport = 50;
      }

      if(percentage >= 73 && percentage <= 77 && ! timeUpdateReported[75]) {
        timeUpdateReported[75] = true;
        finalPercentageToReport = 75;
      }

      if(percentage >= 98 && ! timeUpdateReported[100]) {
        timeUpdateReported[100] = true;
        finalPercentageToReport = 100;
      }
      
      if(finalPercentageToReport) {
        
        createDataLayerEvent({
          'event': 'video',
          'video_name': video.src,
          'video_event': 'Played ' + finalPercentageToReport + "%",
          'video_length': minutes + ':' + seconds
        })
      }
    }

    function videoVolumechange(e) {
      const video = e.target;
      var minutes = parseInt(video.duration / 60, 10);
      var seconds = parseInt(video.duration % 60);

      createDataLayerEvent({
        'event': 'video',
        'video_name': video.src,
        'video_event': video.muted ? 'Sound Off' : 'Sound On',
        'video_length': minutes + ':' + seconds
      })
    }
    return { url, isMobile, videoPlay, videoPause, videoTimeupdate, videoVolumechange };
  },
  mounted() {
    if (this.isMobile) {
      const v = this.$el.querySelector('.video')
      if (v.requestFullscreen) {
        v.requestFullscreen();
      } else if (v.mozRequestFullScreen) {
        v.mozRequestFullScreen();     // Firefox
      } else if (v.webkitRequestFullscreen) {
        v.webkitRequestFullscreen();  // Safari
      } else if (v.msRequestFullscreen) {
        v.msRequestFullscreen();      // IE/Edge
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.video-wrapper {
  width: vw(860, 1920);
  position: relative;
  overflow: hidden;

  @include break($large ) {
    width: vw(860);
  }

  @include break($small ) {
    width: 100%;
    height: auto;
  }
}

.video {
  height: auto;
  width: 100%;
  display: block;
}
</style>