<template>
  <span>{{  counterNumFormatted  }}</span>
  <Waypoint @change="(data) => wpHandler(data)" :tag="'span'" />
</template>
<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { Waypoint } from 'vue-waypoint'

export default {
  name: "runningNumber",
  components: {
    Waypoint,
  },
  props: {
    number: [Number, String],
  },
  setup(props) {

    let counterNum = props.number <= 1 ? ref(props.number) : ref(0);
    const animateValue = (start = 0, end, duration = 500) => {
      if (start === end) return;

      let current = start;
      let increment = Math.ceil(end / 100);

      const timer = setInterval(function () {
        current += increment;
        counterNum.value = current;

        if (current >= end) {
          if (counterNum.value > end) counterNum.value = end;
          clearInterval(timer);
        }
      }, Math.ceil(duration / 100));
    }
    animateValue(0, props.number, 1000);

    const counterNumFormatted = computed(() =>
      counterNum.value.toLocaleString()
    );

    const store = useStore();

    watch(store.state.steps, () => {
      animateValue(0, props.number, 1000);
    });
    watch(props, () => {
      counterNum.value = props.number <= 1 ? props.number : 0
      animateValue(0, props.number, 1000);
    })

    const wpHandler = () => {
      //console.log('MODEL WP R', data.going == "IN" && data.direction == "UP", data)
      animateValue(0, props.number, 1000)
    }

    return { counterNumFormatted, wpHandler };
  },
};
</script>
<style lang="">
</style>