<template>
  <div :class="{ wrapper: 1, 'planning-modal': 1, switching }">

    <div class="col col--first">
      <h3 class="modal-title">{{ content.title }}</h3>
      <div class="running-text" v-html="content.text"></div>
      <ul class="accordion">
        <li class="accordion__item" v-for="(item, index) in items" :key="item.id">
          <button class="accordion__head" :class="{ lighten: item.light, active: item.active }"
            @click="() => setItem(item.id)" @mouseenter="() => light(item.id)" @mouseleave="() => unlight(item.id)">
            {{ index + 1 + "| " + item.title }}
          </button>
          <transition name="accordion" @enter="start" @after-enter="scrolldown" @after-leave="end">
            <p class="accordion__body running-text" v-if="item.active">{{ item.text }}</p>
          </transition>
        </li>
      </ul>
    </div>
    <div class="col col--second">
      <div>
        <div :class="{ 'img-wrapper': 1, [`img-wrapper--${currentModalName}`]: 1 }">
          <img :src="content.media?.url" alt="" />
          <div v-for="(item, index) in items" :key="item.id" class="accordion-tooltip" :class="{ active: item.active }">
            <span class="accordion-tooltip__num" :class="{ lighten: item.light }" @click="() => setItem(item.id)"
              @mouseenter="() => light(item.id)" @mouseleave="() => unlight(item.id)">{{ index + 1 }}</span>
            <transition name="accordion-tooltip">
              <div v-if="item.active" class="accordion-tooltip__content">
                {{ item.tooltip }}
              </div>
            </transition>
          </div>
        </div>
        <p class="bottom-text">{{ content.bottom_text }}</p>
      </div>
    </div>
    <button v-if="hasMixedRoof" class="btn btn--secondary toggle" @click="toggleComponent">
      {{ toggleText }}
    </button>
  </div>
</template>
<script>
// Core
import { ref, toRef, computed } from "vue";
import { useStore } from "vuex";

// External
import { v4 as uid } from "uuid";

export default {
  name: "PlanningModal",
  components: {},
  setup() {
    const store = useStore();
    let content = ref(store.getters.activeModalContent);
    const isMobile = toRef(store.getters, "isMobile");
    const items = ref(
      content.value.items.map((item, index) => ({
        ...item,
        id: uid(),
        active: index === 0,
        light: false,
      }))
    );

    const setItem = (id) => {
      items.value.forEach((item) => {
        if (item.active && item.id === id) {
          item.active = false;
        }
        else {
          item.active = item.id === id
        }
      });
    }

    const start = (el) => {
      el.style.height = el.scrollHeight + "px";

    };

    const scrolldown = () => {
      const el = document.querySelector('.wrapper')
      if (el.offsetHeight < el.scrollHeight && !isMobile.value) {
        el.scroll({
          top: 300,
          left: 0,
          behavior: 'smooth'
        })
      }
      console.dir(el)
    }

    const end = (el) => {
      el.style.height = "";
    };

    const light = (id) =>
      (items.value.find((item) => item.id === id).light = true);

    const unlight = (id) =>
      (items.value.find((item) => item.id === id).light = false);

    const currentModalName = computed(
      () => store.getters.modals.find((modal) => modal.active).name
    );

    const toggleText = ref("");
    toggleText.value =
      currentModalName.value === "planningFlat" ? "לגג רעפים" : "לגג שטוח";

    const switching = ref(false)

    const toggleComponent = () => {
      const modalToOpen =
        currentModalName.value === "planningFlat"
          ? "planningPitched"
          : "planningFlat";


      switching.value = true
      setTimeout(() => {
        store.commit("setModal", modalToOpen);
        content.value = store.getters.activeModalContent;
        items.value = content.value.items.map((item, index) => ({
          ...item,
          id: uid(),
          active: index === 0,
          light: false,
        }));
        toggleText.value =
          currentModalName.value === "planningFlat" ? "לגג רעפים" : "לגג שטוח";
        switching.value = false
      }, 360)
    };

    const { hasMixedRoof } = store.getters

    return {
      content,
      items,
      setItem,
      start,
      scrolldown,
      end,
      light,
      unlight,
      toggleComponent,
      toggleText,
      hasMixedRoof,
      switching,
      currentModalName
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/setup";

.wrapper {
  display: flex;
  justify-content: space-around;
  width: 1020px;
  max-width: 90vw;
  overflow: auto;
  //scrollbar-width: 0;
  //-ms-overflow-style: none;
  transition: opacity 360ms linear;

  &::-webkit-scrollbar {
    //display: none;
  }

  @include break($small ) {
    width: 100%;
    height: initial;
    min-height: 60vh;
    display: block;
  }

  &.switching {
    opacity: 0;
  }
}

.col {
  &--first {
    padding: 55px 55px 20px 0;
    flex: 0 0 400px;

    @include break($small ) {
      padding: 60px 16px 30px;
    }

    .running-text {
      margin-bottom: 16px;
    }
  }

  &--second {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;

    @include break($small ) {
      position: static;
      display: block;
      padding-bottom: 16px;
    }
  }
}

.running-text {
  font-size: 16px;
}

.img-wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
  text-align: center;

  @include break($small ) {
    //max-width: 370px;
    margin: 0 auto;
  }

  img {
    width: 620px;
    max-width: 77vh;

    @include break($small ) {
      width: 90vw;

    }
  }
}

.accordion {
  padding-bottom: 20px;

  .running-text {

    @include break($small ) {
      margin-bottom: 0;
    }
  }

  &__item {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__head {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;

    &::after {
      content: "";
      width: 12px;
      height: 12px;
      background-image: url(@/assets/icon_drop_down.svg);
      background-size: contain;
      display: inline-block;
      margin-right: 25px;
    }

    &.active {
      color: $secondary;
    }

    &.lighten {
      color: $secondary;
    }
  }

  &__body {
    font-weight: 300;
    margin-right: 20px;

    @include break($small ) {
      padding: 0 10px;
    }
  }
}


.accordion-tooltip {
  position: absolute;
  transform: translate(-50%, -50%);

  &.active {
    z-index: 15;

    .accordion-tooltip__num {
      background-color: $secondary;
    }
  }

  &__content {
    background-color: #fff;
    padding: 4px;
    max-width: vw(180, 1920);
    min-width: vw(140, 1920);
    right: 50%;
    transform: translateX(50%);
    z-index: 5;
    position: absolute;
    width: fit-content;
    display: block;
    border-radius: 3px;
    border: 1px solid rgba($primary, 0.3);
    text-align: center;
    bottom: 40px;
    $arrow-size : vw(12, 1920);
    transition: transform 5s;


    @include break($large ) {
      max-width: vw(140);
      min-width: vw(120);
      $arrow-size : vw(12);
    }

    @include break($small ) {
      max-width: 250px;
      min-width: 120px;
      bottom: 40px;
      $arrow-size : 12px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      border-top: $arrow-size solid transparent;
      border-left: $arrow-size*.67 solid transparent;
      border-right: $arrow-size*.67 solid transparent;
    }

    &::before {
      border-top-color: inherit;
      bottom: $arrow-size * -1;
      height: 0;
      width: 0;
    }

    &::after {
      border-top-color: #fff;
      bottom: calc($arrow-size * -1 + 1px);
      z-index: 1;
    }
  }
}


.img-wrapper--planningPitched .accordion-tooltip {
  &:nth-of-type(1) {
    top: 38.5%;
    left: 52.5%;
  }

  &:nth-of-type(2) {
    top: 56.5%;
    left: 49.5%;
  }

  &:nth-of-type(3) {
    top: 37.5%;
    left: 24.5%;
  }

  &:nth-of-type(4) {
    top: 25%;
    left: 69%;
  }

  &:nth-of-type(5) {
    left: 78%;
    top: 45%;
  }
}

.accordion-tooltip {
  position: absolute;
  z-index: 10;

  &:nth-of-type(1) {
    top: 53.4%;
    left: 64%;
  }

  &:nth-of-type(2) {
    top: 37.4%;
    left: 71.8%;
  }

  &:nth-of-type(3) {
    top: 42.5%;
    left: 25%;
  }

  &:nth-of-type(4) {
    top: 30%;
    left: 65%;
  }

  &:nth-of-type(5) {
    left: 46%;
    top: 40%;
  }

  &:nth-of-type(6) {
    left: 41.5%;
    top: 56.5%;
  }

  &:nth-of-type(7) {
    top: 26%;
    left: 37%;
  }

  &__num {
    height: 20px;
    width: 20px;
    background-color: #333;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;


    &.lighten {
      background-color: $secondary;
    }
  }

}

.toggle {
  position: absolute;
  left: 32px;
  top: 24px;
  font-size: 15px;
  height: 40px;
  min-width: 120px;

  @include break($small ) {
    left: 16px;
    top: 16px;
    font-size: 13px;
    height: 34px;
    min-width: 90px;
  }
}

.accordion-leave-active,
.accordion-enter-active {
  transition: height 280ms ease, opacity 280ms ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;

}

.accordion-tooltip-enter-from,
.accordion-tooltip-leave-to {
  transform: translate(50%, -16px);
  opacity: 0;
}

.accordion-tooltip-enter-active,
.accordion-tooltip-leave-active {
  transition: opacity 360ms linear, transform 360ms ease-out;
}

.bottom-text {
  text-align: center;
  max-width: 440px;
  margin: 0 auto;
  padding: 0 15px 5vh;
  font-size: 16px;

  @include break($large) {
    font-size: 14px;
  }

  @include break($small) {
    //display: none;
    font-size: 13px;
  }
}
</style>