// Core
import { shallowRef } from 'vue';

// Components
import TextMedia from '@/components/modals/TextMedia.vue'
import HardwareModal from '@/components/modals/HardwareModal.vue'
import EnvironmentModal from '@/components/modals/EnvironmentModal.vue'
import PlanningModal from '@/components/modals/PlanningModal'
import VideoModal from '@/components/modals/VideoModal'
import WarrantyModal from '@/components/modals/WarrantyModal.vue'
import ContactExpert from '@/components/modals/ContactExpert.vue'
import MobileCalc from '@/components/modals/MobileCalc.vue'


export default [

    {
        name: 'installation',
        active: false,
        component: shallowRef(TextMedia),
    },
    {
        name: 'output',
        active: false,
        component: shallowRef(TextMedia),
    },
    {
        name: 'hardware',
        active: false,
        component: shallowRef(HardwareModal),
    },
    {
        name: 'environment',
        active: false,
        component: shallowRef(EnvironmentModal),
    },
    {
        name: 'planningFlat',
        active: false,
        component: shallowRef(PlanningModal),
    },
    {
        name: 'planningPitched',
        active: false,
        component: shallowRef(PlanningModal),
    },
    {
        name: 'video',
        active: false,
        component: shallowRef(VideoModal)
    },
    {
        name: 'warranty',
        active: false,
        component: shallowRef(WarrantyModal)
    },
    {
        name: 'maintenance',
        active: false,
        component: shallowRef(TextMedia)
    },
    {
        name: 'contact',
        active: false,
        component: shallowRef(ContactExpert)
    },
    {
        name: 'mobileCalc',
        active: false,
        component: shallowRef(MobileCalc)
    },
]
