<template>
  <div class="wrapper environment-modal">
    <h2 class="modal-title">{{  content.title  }}</h2>
    <p class="text">{{  content.text  }}</p>
    <div class="items">
      <div class="item">
        <img :src="content.fuel.icon.url" alt="" />
        <div class="item__num">
          <running-number :number="Math.round(LitersOfGasoline)" />
        </div>
        {{  content.fuel.text  }}
      </div>
      <div class="item">
        <img :src="content.trees.icon.url" alt="" />
        <div class="item__num">
          <running-number :number="Math.round(Trees)" />
        </div>
        {{  content.trees.text  }}
      </div>
      <div class="item">
        <img :src="content.coal.icon.url" alt="" />
        <div class="item__num">
          <running-number :number="Math.round(TonsOfCoalBurned)" />
        </div>
        {{  content.coal.text  }}
      </div>
      <div class="item">
        <img :src="content.waste.icon.url" alt="" />
        <div class="item__num">
          <running-number :number="Math.round(TonsOfWaste)" /> <span>טון</span>
        </div>
        {{  content.waste.text  }}
      </div>
    </div>
  </div>
</template>
<script>
// Core
import { ref } from "vue";
import { useStore } from "vuex";

// componenta
import runningNumber from "@/components/runningNumber.vue";

export default {
  name: "EnvironmentModal",
  components: {
    runningNumber,
  },
  props: {},
  setup() {
    const store = useStore();
    const {
      userData: {
        ProposalOutput: { TonsOfCoalBurned, TonsOfWaste, LitersOfGasoline, Trees },
      },
    } = store.getters;
    let content = ref(store.getters.activeModalContent);

    return { content, TonsOfCoalBurned, TonsOfWaste, LitersOfGasoline, Trees };
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/setup";

.wrapper {
  max-width: 90vw;
  padding: vw(65, 1920) vw(52, 1920);
  width: vw(1000, 1920);

  @include break($large) {
    padding: 4vh 5vw;
    width: vw(800);
  }

  @include break($small) {
    padding: 50px 24px;
    width: vw(715);
  }

  @include break($small ) {
    width: 100%;
    max-width: initial;
  }
}



.text {
  font-size: vw(26, 1920);
  margin-bottom: vw(30, 1920);

  @include break($large ) {
    font-size: vw(20);
    margin-bottom: vw(15);
  }

  @include break($small ) {
    font-size: 20px;
    max-width: 260px;
    margin-bottom: 16px;
    font-weight: 600;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  justify-content: space-between;

}

.item {
  text-align: center;
  font-weight: normal;
  font-size: vw(24, 1920);
  flex: 0 0 calc(50% - vw(6, 1920));
  background-color: $light-bg;
  box-shadow: 0 4px 15px 0 rgba(22, 55, 105, 0.25);
  padding: 2vh 5vw 1vh;
  border-radius: 2px;

  @include break($large ) {
    font-size: vw(18);
    flex: 0 0 calc(50% - vw(4));
  }

  @include break($small ) {
    height: 186px;
    flex: 0 0 calc(50% - 4px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
  }

  &:nth-child(1),
  &:nth-child(2) {
    margin-bottom: vw(12, 1920);

    @include break($large ) {
      margin-bottom: vw(8);
    }

    @include break($small ) {
      margin-bottom: 8px;
    }
  }

  img {
    display: block;
    margin: 0 auto;

    @include break($small ) {
      margin-bottom: 4px;
    }
  }

  &__num {
    font-size: vw(34, 1920);
    font-weight: bold;

    @include break($large ) {
      font-size: vw(26);
    }

    @include break($small ) {
      font-size: 30px;
    }
  }
}
</style>