export const dataApiUrl = 'https://voltasolar.outsystemsenterprise.com/ProposalGeneration_CS/rest/PriceOffer/Resi'
export const contactApiUrl = 'https://voltasolar.outsystemsenterprise.com/ProposalGeneration_CS/rest/PriceOffer/ResiContact'
export const propsalIdParamName = 'proposal_id'
export const contentApiUrl = 'https://v3-admin.volta.layerbrandingltd.com/wp-json/offer/v1/data'
export const makeApiUrl = 'https://hook.eu1.make.com/u11vglz4qglyo0fuersff7kmxb63fghe'

export const paymentUUIDUrl = 'https://voltasolar.outsystemsenterprise.com/Meshulam_API/rest/Generation/SelfConversion'
export const paymentUUIDAPIKey = 'Apikey'
export const paymentUUIDAPIValue = '434b3d36-a814-4aba-93a3-44d11f358849'
export const paymentIframeUrl = 'https://voltasolar.outsystemsenterprise.com/Payments/PaymentPage/'

export const paymentIframeSuccess = 'https://sc.volta.netrise.co.il/paymentPages/success.html?'
export const paymentIframeFailure = 'https://sc.volta.netrise.co.il/paymentPages/failure.html'

export const googleSheetUrl = 'https://script.google.com/macros/s/AKfycbzg9y3U0ZMloa1AplxkaK0Xr2zAZGegNif9IsX_maqUBkDoWwGIPH2EiQpvYDo-MR4I/exec'
