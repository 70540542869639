<template>
  <main class="main about">
    <div class="main__inner">
      <div class="layout">
        <volta-calculator></volta-calculator>
      </div>
    </div>
  </main>
</template>
<script>
// Components
import VoltaCalculator from "@/components/Calculator.vue";

export default {
  name: "ViewCalculator",
  components: {
    VoltaCalculator
  },
  setup() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";


.main__inner {
  align-items: flex-start;
}

.layout {
  width: 100%;
}

@media screen and (max-width: 1366px) {
  .main__inner {
    align-items: center;
  }
}

@media screen and (max-width: 990px) {
  .layout {
    --header-height: 80px;
    padding-top: var(--header-height);
    height: calc(100vh + var(--header-height));
  }
}
</style>