<template>
  <div :class="{ modal: 1, [`modal--${store.getters.activeModal?.name}`]: 1 }">
    <div class="modal__inner">

      <button class="modal__close" @click="close">
        <img :src="require('@/assets/close.svg')" alt="סגןר חלונית" />
      </button>
      <slot></slot>
    </div>
  </div>
</template>
<script>
// Core
import { useStore } from "vuex";

export default {
  name: "ModalBox",

  setup() {
    const store = useStore();
    function close() {
      store.commit("closeModal");
    }

    document.addEventListener('keydown', e => {
      if (e.key == 'Escape') close()
    });

    return { close, store };
  },
};
</script>
<style lang="scss">
@import "@/scss/setup";

.modal {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3vw;
  overflow-x: visible;

  @include break($small) {
    padding: 5vw;
  }

  &--contact {
    .modal__inner {
      @include break($small ) {
        width: fit-content;
      }
    }
  }

  &--video {
    .modal__inner {
      overflow: unset;
    }

    .modal__close {
      right: -10px;
      top: -45px;
      padding: 10px;
      border-radius: 100px;
      z-index: 10;
      transition: all 0.3s;

      img {
        filter: brightness(0) invert(1);
      }

      @include break($small ) {
        padding: 0px;
        height: 16px;
        width: 16px;
        right: 0;
        top: -36px;
      }
    }
  }


  &__inner {
    background-color: #fff;
    position: relative;
    border-radius: 22px;
    overflow-y: hidden;
    overflow-x: visible;
    box-shadow: 0px 5px 12px 2px rgba(4, 60, 127, 0.3);
    max-height: 100%;

    @include break($small) {
      max-height: 100%;
      min-width: initial;
      min-height: initial;
      background-color: $light-bg;

    }

    .wrapper {
      max-height: 82vh;
      overflow-x: hidden;

    }
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 18px;
    padding: 10px;
    border-radius: 100px;
    z-index: 10;
    transition: all 0.3s;

    &:hover {
      background-color: #00358a12;
    }

    @include break($small ) {
      padding: 0px;
      height: 20px;
      width: 20px;
      right: 20px;
      top: 20px;
      overflow: visible;

      &:hover {
        background-color: transparent;
      }
    }

    img {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
}
</style>