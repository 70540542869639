import { createApp } from 'vue'
import LottieAnimation from "lottie-web-vue";
import App from './App.vue'
import store from './store'

const handleBeforeUnload = (e) => {
  if (store.getters.customerFormSent) return;

  e.preventDefault();

  store.dispatch({ type: "postCustomerForm" });
  // const confirmationMessage = 'Are you sure you want to leave?';
  // e.returnValue = confirmationMessage; // Standard way to show the confirmation dialog in modern browsers
  // return confirmationMessage; // For some older browsers
};

window.addEventListener('beforeunload', handleBeforeUnload);

const app = createApp(App)
  .use(store)
  .use(LottieAnimation);
app.config.warnHandler = () => { };
app.mount('#app')
