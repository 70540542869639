<template>
  <main class="main intro">
    <div class="main__inner">
      <div class="layout">
        <!-- Customer details form -->
        <div class="pannel">
          <div v-show="sendingForm" class="form-loader">
            <img src="../assets/loader.gif" alt="loader">
          </div>
          <form v-on:submit.prevent="onSubmit" class="customer-form">
            <label class="sm customer-input" for="fullname"><span>שם מלא</span>
              <input :class="{ 'errorInput': fullnameError }" v-model="fullname" @input="validateFullname" type="text" name="fullname"
                placeholder="שם מלא" />
              <span v-if="fullnameError" class="errorMessage">{{ fullnameError }}</span>
            </label>

            <label  class="sm customer-input" for="phone"><span>נייד</span>
              <input :class="{ 'errorInput': phoneError }" v-model="phone" @input="validatePhone" type="tel" name="phone"
                placeholder="מספר נייד" />
              <span v-if="phoneError" class="errorMessage">{{ phoneError }}</span>
            </label>

            <search-autocomplete ref="searchRef" />

            <label class="m customer-input" for="email"><span>אימייל</span>
              <input :class="{ 'errorInput': emailError }" v-model="email" @input="validateEmail" type="text" name="email"
                placeholder="כתובת אימייל" />
              <span v-if="emailError" class="errorMessage">{{ emailError }}</span>
            </label>

            <label class="acct__label l customer-input">
              <input class="storage-checkbox" v-model="isStorage" type="checkbox" name="isStorage" id="acct0" value="אגירה">
              <span class="acct__text">אני מעוניין לשמוע על אגירה</span>
            </label>

            <span v-if="formError" class="errorMessage">{{ formError }}</span>

            <button v-show="!sendingForm" type="submit" class="customer-form__submit"><span>שליחה</span></button>

            <!-- Hidden image, this preloads it for the checkbox -->
            <img class="hide" src="../assets/icon-check-secondary.svg" alt="check-icon">
          </form>
        </div>
      </div>
    </div>
    <div class="lottie-wrapper">
      <lottie-animation class="anim1" v-if="isMobile" ref="animMob" :animationData="mobileAnimation" />
      <lottie-animation v-else class="anim2" ref="animDesk" :animationData="desktopAnimation" />
    </div>
    <div v-if="!isMobile" class="credit">
      created by:
      <a href="https://netrise.co.il/" target="_blank">Netrise.co.il</a>
    </div>
  </main>

</template>

<script>
/* eslint-disable */

// Core
import {
  ref,
  toRef,
  watch,
  computed,
  onMounted,
} from "vue";
import { useStore } from "vuex";

// import { formatPhone, createDataLayerEvent } from '@/inc/helpers'
import SearchAutocomplete from "@/components/Autocomplete.vue";

export default {
  name: "ViewCustomer",
  components: {
    SearchAutocomplete,
  },

  setup() {
    let utm_source = '';
    let utm_campaign = '';
    let utm_medium = '';
    let utm_term = '';
    let source = '';
    let campaign = '';

    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      utm_source = urlParams.get('utm_source');
      utm_campaign = urlParams.get('utm_campaign');
      utm_medium = urlParams.get('utm_medium');
      utm_term = urlParams.get('utm_term');
      source = urlParams.get('Source');
      campaign = urlParams.get('Campaign');
    })

    const searchRef = ref(null);

    // form fields
    const fullname = ref('');
    const phone = ref('');
    const email = ref('');
    const isStorage = ref('');

    const fullnameError = ref('');
    const phoneError = ref('');
    const emailError = ref('');
    const formError = computed(() => store.state.apiResponse === false ? 'אירעה שגיאה, אנא נסו שנית' : '');
    const sendingForm = computed(() => store.state.sendingForm);

    function validateFullname() {
      if (!fullname.value) {
        fullnameError.value = 'שדה זה הינו חובה.';
      } else if (!fullname.value.match(/[\u0590-\u05FF\s]+/)) {
        fullnameError.value = 'תווים בעברית בלבד.';
      } else {
        fullnameError.value = '';
      }
    }

    function validatePhone() {
      if (!phone.value) {
        phoneError.value = 'שדה זה הינו חובה.';
      } else if (!phone.value.match(/^((0\d{1,2}\-\d{7})|(0\d{8,9}))$/)) {
        phoneError.value = 'מספר הטלפון אינו תקין.';
      } else {
        phoneError.value = '';
      }
    }

    function validateEmail() {
      if (!email.value) {
        emailError.value = 'שדה זה הינו חובה.';
        // Regex for email validation as per W3C HTML5 spec:
        // https://html.spec.whatwg.org/multipage/forms.html#valid-e-mail-address
      } else if (!email.value.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
        emailError.value = 'כתובת האימייל שהוזנה אינה תקינה.';
      } else {
        emailError.value = '';
      }
    }

    function validateCity() {
      return searchRef.value.validate();
    }

    const formInvalid = computed(() => {
      return !validateCity() && !fullnameError.value && !phoneError.value && !emailError.value;
    })

    function clearForm() {
      fullname.value = '';
      phone.value = '';
      email.value = '';
      isStorage.value = '';
      searchRef.value.clear();
    }

    function onSubmit() {
      validateFullname();
      validatePhone();
      validateEmail();

      if (!formInvalid.value) return;

      const formData = {
        Name: fullname.value,
        Email: email.value,
        Phone: phone.value,
        City: searchRef.value.getValue(),
        isStorage: isStorage.value ? 'true' : 'false',
        utm_source,
        utm_campaign,
        utm_medium,
        utm_term,
        Source: source,
        Campaign: campaign,
        Funnel: 'SelfConversion',
      };

      if (!store) return;

      store.state.steps[2].canAccess = true;
      store.commit("setStep", 2);
      store.commit("setFormData", formData);
      store.dispatch('triggerReload');

      // store.dispatch({
      //   type: "postCustomerForm",
      //   filteredFormData
      // });
    }

    const animMob = ref(null)
    const animDesk = ref(null)
    const store = useStore();
    const isMobile = toRef(store.getters, "isMobile");
    const startAnimation = ref(true);
    const mobileAnimation = require("@/assets/house_mobile.json");
    const desktopAnimation = require("@/assets/lottie/desktop_house.json");
    const activeBtnWidth = ref(0)

    // function sendDataLayerEvent(btn) {
    //   createDataLayerEvent({
    //     'event': 'contact_us',
    //     'contact_medium': btn,
    //     'button_location': 'Main Page'
    //   });
    // }

    watch(
      () => store.state.currentStep,
      () => {
        startAnimation.value =
          store.state.steps.find((step) => step.active).component?.name ===
          "ViewIntro";

        const anim = animMob.value || animDesk.value;
        // if (anim) startAnimation.value ? anim.play() : anim.stop();
        if (anim) anim.play();
      }
    );

    watch(
      () => store.state.apiResponse,
      (newValue) => {
        if (newValue) clearForm();
      }, { deep: true });

    return {
      isMobile,
      mobileAnimation,
      desktopAnimation,
      startAnimation,
      activeBtnWidth,
      animMob,
      animDesk,
      onSubmit,
      // sendDataLayerEvent,
      fullname,
      phone,
      email,
      isStorage,
      fullnameError,
      phoneError,
      emailError,
      formInvalid,
      searchRef,
      formError,
      sendingForm,
      validateFullname,
      validatePhone,
      validateEmail
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/setup";

.form-loader img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.form-loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  z-index: 5;
}

.errorInput {
  border-color: rgba(247, 102, 47, .6);
  background-color: rgba(247, 102, 47, .04);
}

.errorMessage {
  font-size: 16px;
  line-height: 21px;
  color: #f7662f;
  overflow-wrap: break-word;
  text-wrap: wrap;

  @include break($medium) {
    line-height: 100%;
    font-size: 14px;
  }
}

.customer-form__submit {
  position: absolute;
  bottom: -5%;
  right: auto;
  left: 30px;
  padding: 16px 40px 16px 57px;
  background: url(../assets/arrow-left-primary.svg) center left 30px no-repeat, #f7be2f;
  background-size: 9px, 100%;

  border: none;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: #00358a;

  @include break($medium) {
    padding: 10px 40px 10px 57px;
    font-size: 15px;
  }
}

.customer-form__submit .glow {
  display: block;
  height: 160px;
  width: 160px;
  background: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.6), transparent);
  position: absolute;
  border-radius: 100%;
  top: -80px;
  z-index: 2;
}

.hide {
  visibility: 0;
  width: 0;
  height: 0;
}

#acct0 {
  width: 0;
  display: inline-block;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
}

#acct0+span {
  position: relative;
  padding-right: 21px;
  text-align: right;
  font-size: 14px;
  line-height: 23px;
  white-space: normal;
}

#acct0:checked+span::before {
  background: url(../assets/icon-check-secondary.svg) center center no-repeat, #00358a;
  background-size: 8px, 100%;
}

.acct__label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.acct__text::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 3px;
  background-color: #f5f8fd;
  border: 1px solid rgba(125, 152, 197, .3);
  border-radius: 2px;
}

.pannel {
  position: relative;
  height: auto;
  width: 100%;
  padding: 60px 100px 100px 50px;
  margin-top: min(5vh, 5vw);
  background-color: #fff;

  @include break($large) {
    padding: vw(10) vw(25) 16px vw(25);
  }

  @include break($small) {
    padding: 0;
  }
}

.pannel::after {
  content: "";
  position: absolute;
  top: 110px;
  right: 50px;
  left: 50px;
  bottom: -50px;
  background-color: rgba(52, 85, 138, .9);
  -webkit-filter: blur(50px);
  filter: blur(50px);
  opacity: .2;
  z-index: -1;
}

.intro {
  background-color: $light-bg;

  @include break($small) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }

  .main__inner {
    z-index: 2;
    position: relative;
  }
}

.subtitle {
  font-size: vw(38, 1920);
  font-weight: bold;

  &:first-of-type {
    margin-top: vw(44, 1920);
  }

  @include break($large) {
    font-size: vw(34);

    &:first-of-type {
      margin-top: vw(44);
    }
  }

  @include break($small) {
    font-size: 6vw;
    font-weight: normal;
  }
}

.contact-text {
  font-size: vw(34, 1920);
  margin-top: vw(50, 1920);
  font-weight: 300;
  margin-bottom: vw(25, 1920);

  @include break($large) {
    font-size: vw(26);
    margin-top: vw(60);
    margin-bottom: vw(25);
  }

  @include break($small) {
    font-size: 21px;
    font-size: 5vw;
    font-weight: 300;
    margin-top: 40px;
    margin-bottom: 32px;
  }
}

.layout {
  width: 50%;
  height: 100%;
  margin-left: auto;

  @include break($medium) {
    width: 55%;
  }

  @include break($small) {
    width: 100%;
  }
}

.icons {
  display: flex;
  align-items: center;


  .icon {
    overflow: hidden;
    background-color: $secondary;
    height: vw(90, 1920);
    width: vw(90, 1920);
    overflow: hidden;
    border-radius: vw(45, 1920);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: width 230ms ease-out, background-color 180ms ease-out;
    margin-left: vw(12);


    @include break($large) {
      height: vw(90);
      width: vw(90);
      border-radius: vw(45);
    }

    @include break($small) {
      height: 20vw;
      width: 20vw;
      height: 45px;
      width: 45px;
      border-radius: 100px;
      margin-left: 2vw;
    }

    &.active {
      /*  width: vw(350, 1920);

      @include break($large) {
        width: vw(350);
      }
      @include break($small) {
        width: 180px;
      }
      */
    }

    &--wa {
      background-color: #2fbe39;

      &:hover {
        background-color: darken(#2fbe39, 4%);
      }
    }

    &--primary {
      background-color: $primary;

      &:hover {
        background-color: darken($primary, 4%);
      }
    }

    &--secondary {
      background-color: $secondary;

      &:hover {
        background-color: darken($secondary, 4%);
      }
    }

    &__link {
      white-space: nowrap;
      margin: 0 vw(20, 1920);
      font-size: vw(30, 1920);
      color: #fff;
      direction: ltr;

      @include break($large) {
        margin: 0 vw(20);
        font-size: vw(30);
      }

      @include break($small) {
        font-size: 18px;
        margin: 0;
      }

      &--email {
        @include break($small) {
          font-size: 15px;
        }
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 vw(90, 1920);

      @include break($large) {
        flex: 0 0 vw(90);
      }

      @include break($small) {
        flex: 0 0 45px;
      }

      &--wa {
        svg {
          width: 90px;
          height: 90px;
          max-width: 100%;
        }
      }
    }
  }

  svg {
    height: 58px;
    width: auto;
    max-width: 70%;
  }
}

.lottie-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  direction: ltr;
  z-index: 0;
  max-height: 115%;

  @include break($small) {
    position: static;
  }

  >div {
    width: 100vw;

    @include break($large) {
      height: auto;
    }
  }
}

.credit {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  z-index: 20;
  font-size: 15px;
}
</style>
<style lang="scss">
.lottie-wrapper {
  svg {
    display: block;
  }
}
</style>
