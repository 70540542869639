<template>
  <main class="main about">
    <div class="main__inner">
      <div v-if="src" class="layout">
        <h3 class="page-title">
          990 ש"ח ישמשו כתשלום דמי טיפול ליציאה לדרך וכלולים במחיר המערכת.
          <br>
          לאחר התשלום מומחה סולארי יצור עמך קשר להמשך התהליך.
          <br>
          תודה שבחרת בנו!
        </h3>
        <iframe ref="iframeRef" :src="src" frameborder="0"></iframe>
        <p class="payment-notice">*לתשומת לבך: המחיר המצוין עשוי להשתנות בין מועד ההזמנה לבין מועד החתימה כמפורט בהסכם
          ההזמנה. <span><a class="policy-link" target="_blank" :href="policyLink">לפרטים נוספים</a></span></p>
      </div>
    </div>
  </main>
  <div v-if="!src" class="loader"></div>
</template>
<script>
// Core
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";

export default {
  name: "ViewPayment",
  setup() {

    const store = useStore();
    const src = ref('');
    const policyLink = ref('');
    const iframeRef = ref(null);
    /**
     * Iframe URL
     */
    const paymentIframe = computed(() => store.getters.paymentIframe);
    const policy = computed(() => store.getters.policyLink);
    const reload = computed(() => store.state.reloadIframe);

    watch(paymentIframe, (newSrc) => src.value = newSrc);
    watch(policy, (newLink) => policyLink.value = newLink);
    watch(reload, (toReload) => {
      if (toReload.value) {
        resetIframe();
      }
    });

    const reloadIframe = () => iframeRef.value.src = store.getters.paymentIframe;
    const resetIframe = () => src.value = '';

    window.addEventListener('message', event => event.data === 'retryPayment' && reloadIframe());

    return { src, policyLink, reload, reloadIframe, resetIframe, iframeRef };
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.main__inner {
  align-items: flex-start;
}

.layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  // max-width: 1500px;
}

h3.page-title {
  padding-bottom: 1vw;
  line-height: 100%;
  text-align: center;
}

iframe {
  width: 22vw;
  min-width: 350px;
  height: 55vh;
}

.payment-notice {
  top: 75vh;
  position: absolute;
}

.policy-link {
  text-decoration: underline;
}

@media screen and (max-width: 990px) {
  .layout {
    padding-top: 80px;
  }

  h3.page-title {
    padding-bottom: 2vh;
  }

  iframe {
    height: 100vh;
  }
}

@media screen and (max-width: 480px) {
  .payment-notice {
    top: 85vh;
  }
}
</style>