<template>
  <div class="wrapper warranty">
    <h3 class="modal-title">{{  title  }}</h3>
    <div class="warranty__inner">
      <div v-if="!isMobile" class="warranty__first warranty__text"><span>{{  text_1  }}</span></div>
      <div v-if="!isMobile" class="warranty__first warranty__subtext"><span v-html="sub_text_1"></span></div>
      <div class="warranty__content">
        <div v-if="isMobile">
          <div class="warranty__text"><span>{{  text_1  }}</span></div>
          <div class="warranty__subtext"><span v-html="sub_text_1"></span></div>
        </div>
        <div>
          <div class="warranty__text"><span>{{  text_2  }}</span></div>
          <div class="warranty__subtext"><span>{{  InstallationWarranty  }} שנים</span></div>
        </div>
        <div class="warranty__animation">
          <lottie-animation ref="warrantyAnim" :animationData="animation" :loop="true" />
        </div>
        <div>
          <div class="warranty__text"><span>{{  text_3  }}</span></div>
          <div class="warranty__subtext"><span v-html="sub_text_3"></span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRef } from '@vue/reactivity';
import { useStore } from 'vuex'
export default {
  name: "WarrantyModal",
  setup() {
    const animation = require('@/assets/shield.json')
    const store = useStore()
    const isMobile = toRef(store.getters, 'isMobile')
    const content = store.getters.activeModalContent
    const { InstallationWarranty } = store.getters.userData.ProposalOutput

    return { animation, isMobile, ...content, InstallationWarranty };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.warranty {
  padding: vw(36) vw(66);
  height: vw(660, 1920);
  width: vw(1040, 1920);
  max-width: 100%;
  max-height: 85vh;
  display: flex;
  flex-direction: column;

  @include break($large ) {
    padding: vw(60, 1920) vw(66, 1920);
    width: vw(1020);
    height: vw(560);
    max-height: 85vh;
  }

  @include break($small ) {
    height: initial;
    min-height: 70vh;
    padding: 55px 16px 20px;
    width: 100%;
  }

  .modal-title {
    margin-bottom: vw(40, 1920);
    text-align: center;

    @include break($large ) {
      margin-bottom: vw(40);
    }
  }

  &__inner {
    font-size: vw(26, 1920);
    font-weight: bold;
    text-align: center;
    margin: auto;


    @include break($large ) {
      font-size: vw(26);
    }
  }


  &__text {
    margin-bottom: vw(5, 1920);

    span {
      border-bottom: 2px solid $secondary;
      padding-bottom: 1px;
    }

    @include break($large ) {
      margin-bottom: vw(5);
    }

    @include break($small ) {
      margin-top: 15px;
      font-size: 20px;


    }
  }

  &__subtext {
    margin-bottom: vw(22, 1920);
    font-size: vw(26, 1920);
    font-weight: normal;

    @include break($large ) {
      margin-bottom: vw(22);
      font-size: vw(24);
    }

    @include break($small ) {
      margin-top: 5px;
      font-size: 18px;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 40px;

    @include break($small ) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 0;
    }


    >div {
      flex: 0 0 33.333%;
      min-height: 180px;

      @include break($small ) {
        flex-basis: 100%;
        min-height: unset;
      }
    }
  }

  &__animation {
    max-width: 200px;
    padding: 0;

    @include break($small ) {
      order: -1;
      max-width: 120px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>