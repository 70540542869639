import { propsalIdParamName } from '@/store/constants'
export const getProposalIdFromUrl = () => (new URLSearchParams(location.search)).get(propsalIdParamName)

export const getOffset = (el) => {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

export const formatPhone = (phone) => phone.startsWith('+') && phone.replace('+972', '0').slice(0, 3).concat('-', phone.slice(6))

// eslint-disable-next-line
export const createDataLayerEvent = (event) => typeof dataLayer != "undefined" && dataLayer.push(event) && console.log("DataLayer Event", event)