<template>
  <div class="wrapper hardware-modal">
    <h2 class="modal-title">{{ content.title }}</h2>
    <div class="items">
      <div class="item" v-for="(item, i) in content.items" :key="i">
        <div class="item__top">
          <h3 class="item__title">{{ item.title }}</h3>
          <img :src="item.icon.url" alt="" />
        </div>
        <p class="item__txt">{{ item.text }}</p>
        <a v-if="content.button_1 && isMobile && i === 0" :href="content.button_1.url"  @click="() => createDataLayerEvent({ 'event': 'system_simulation', 'download_spec': 'Panels' })"
          class="btn btn--primary mobile-btn" :target="content.button_1.target">{{ content.button_1.title }}</a>
      </div>
    </div>

    <div class="btns" v-if="content.button_1 || content.button_2">
      <a v-if="content.button_1 && !isMobile" :href="content.button_1.url" class="btn btn--primary"  @click="() => createDataLayerEvent({ 'event': 'system_simulation', 'download_spec': 'Panels' })"
        :target="content.button_1.target">{{ content.button_1.title }}</a>
      <a v-if="content.button_2" :href="content.button_2.url" class="btn btn--secondary"   @click="() => createDataLayerEvent({ 'event': 'system_simulation', 'download_spec': 'Optimizer & Converter' })"
        :target="content.button_2.target">
        {{ content.button_2.title }}
      </a>
    </div>
  </div>
</template>
<script>
// Core
import { ref, toRef } from "vue";
import { useStore } from "vuex";
import { createDataLayerEvent } from '@/inc/helpers'

export default {
  name: "HardwareModal",
  props: {},
  setup() {
    const store = useStore();
    const content = ref(store.getters.activeModalContent);
    console.log('content', content)
    const isMobile = toRef(store.getters, 'isMobile')

    return { content, isMobile, createDataLayerEvent };
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/setup";

.wrapper {
  width: 1020px;
  padding: 60px 50px 90px;
  max-width: 90vw;
  overflow: hidden;

  @include break($small ) {
    padding: 50px 16px 16px;
    max-width: initial;
    width: 100%;
    overflow: auto;
  }
}

.modal-title {
  @include break($small ) {
    margin-bottom: 20px;
  }
}

.items {
  display: flex;
  justify-content: space-between;

  @include break($small ) {
    display: block;
  }
}

.item {
  @include break($small ) {
    margin-bottom: 20px;
  }

  &__top {
    display: flex;
    align-items: center;
    height: 90px;

    @include break($small ) {
      height: auto;
    }
  }

  &__title {
    font-size: 30px;
    margin-left: 25px;

    @include break($small ) {
      font-size: 24px;
    }
  }

  img {
    @include break($small ) {
      max-width: 40px;
      margin-right: 6px;
    }
  }

  &__txt {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.45;
    margin-top: 30px;
    max-width: 250px;

    @include break($small) {
      font-size: 15px;
      margin-top: 8px;
      max-width: initial;
    }

  }

  &:not(:first-child) {
    @include break($small ) {
      background-color: $dark-bg;
      padding: 7px 10px;
      margin-bottom: 0;
    }
  }



  &:nth-child(2) {
    @include break($small ) {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      padding-top: 10px;
    }
  }
}

.btns {
  margin-top: vw(70, 1920);

  @include break($large ) {
    margin-top: vw(32);
  }



  @include break($small ) {
    background-color: $dark-bg;
    margin-top: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    padding: 10px;
  }

  .btn {
    font-size: 15px;
    padding: 0 40px;
    height: 45px;

    &:not(:last-child) {
      margin-left: 333px;
    }

    @include break($small ) {
      width: 100%;
    }
  }
}

.mobile-btn {
  margin-top: 16px;
}
</style>