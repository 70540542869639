export default {
  type: {
    tiles: 'רעפים',
    flat: 'בטון',
    mixed: 'רעפים + בטון'
  },
  shade: {
    none: 'אין',
    little: 'מעט',
    alot: 'הרבה'
  },
  direction: {
    southeast: 'דרום - מזרח',
    south: 'דרום',
    southwest: 'דרום - מערב',
    eastwest: 'מזרח - מערב',
  }
}