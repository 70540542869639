<template>
    <div class="wrapper">
        <h3 class="modal-title">מחשבון ליסינג</h3>
        <p>
            רוצים להתאים את מודל הליסינג?<br>
            שנו את גובה המענק ו/או את מועד תחילת ההכנסות ולחצו חישוב
        </p>
        <div class="set-calc">
            <div class="set-calc__col">
                <p class="">תחילת הכנסות לאחר:</p>
                <div class="set-calc__btns">
                    <button v-for="(num, index) in delays" :key="num"
                        :class="{ active: index == selectedProgram.Delay }" @click="() => setDelay(index)">{{ num
                        }}</button>
                </div>
            </div>
            <div class="set-calc__col">
                <label for="grant"> מענק:</label>
                <Slider v-if="showSlider" v-model="grant" direction="rtl" :lazy="false" :min="minRange" :max="maxRange"
                    :step="stepRange" :format="val => formatSliderValue(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    @update="(value) => setLeasingStep(value)" />
            </div>
        </div>
        <button class="btn btn--secondary full-width" @click="close">
            חישוב
        </button>

    </div>
</template>
<script>
// core
import { useStore } from "vuex";
import { nextTick, ref, toRef } from "vue";

// 3rd
import Slider from '@vueform/slider'


export default {
    components: { Slider },
    setup() {
        const store = useStore();
        const { commit } = store
        const leasindData = store.getters.userData.LeasingSteps;

        const selectedProgram = toRef(store.state, 'currentLeasing')
        const stepRange = 1
        const minRange = 0
        const maxRange = 5
        const grant = selectedProgram.value ? selectedProgram.value.Step : 3
        const showSlider = ref(true)

        const delays = ['מיידי', 'שנה', 'שנתיים', 'שלוש שנים']

        commit('setCurrentLeasing', selectedProgram.value ? { step: selectedProgram.value.Step, delay: selectedProgram.value.Delay } : { step: 3, delay: 0 })

        const formatSliderValue = (val) => '₪' + leasindData.find(el => el.Step == val && el.Delay == selectedProgram.value.Delay).PrePayment

        const reRenderSlider = () => {
            showSlider.value = false
            nextTick(() => {
                showSlider.value = true
            })
        }

        const setDelay = (index) => {
            commit('setCurrentLeasing', { step: selectedProgram.value.Step, delay: index })
            reRenderSlider()
        }
        const setLeasingStep = (value) => commit('setCurrentLeasing', { step: value, delay: selectedProgram.value.Delay })

        function close() {
            store.commit("closeModal");
        }

        return { grant, setDelay, setLeasingStep, selectedProgram, stepRange, minRange, maxRange, formatSliderValue, showSlider, close, delays };
    },
};
</script>
<style lang="scss" scoped>
@import '@vueform/slider/themes/default.css';
@import "@/scss/setup";

.wrapper {
    padding: 44px 20px 44px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
}

.set-calc {

    &__col {
        background-color: #e9eef7;
        border-radius: 2px;
        padding: 8px 16px;
        margin: 16px 0;
        font-size: 15px;

        &:nth-child(2) {
            @include break($small) {
                padding-left: 22px;
                max-width: calc(90vw - 40px);
                overflow-x: visible;
            }
        }
    }

    label {
        display: block;
        margin-bottom: 15px;
    }




    &__btns {
        display: flex;
        justify-content: space-between;
        gap: 1.5%;
        margin: 16px 0;
    }

    button {
        border-radius: 4px;
        border: solid 1px #00358a;
        color: #00358a;
        font-weight: 600;
        flex: 0 1 25%;
        padding: 2%;
        font-size: 16px;

        &:hover,
        &:focus,
        &.active {
            background-color: #1855ad;
            border: solid 1px #1855ad;
            color: #fff;
        }
    }

    .slider-target {
        padding: 24px 0;
    }

}
</style>