<template>
  <volta-calc data='{"talkToRepresentativeUrl": "https://app.acuityscheduling.com/schedule.php?owner=25554738"}'
    ref="calc"></volta-calc>
</template>

<script>
import { useStore } from "vuex";
import calcData from "@/store/calcData";

export default {
  name: 'VoltaCalculator',
  mounted() {
    this.loadWebComponent();
  },
  methods: {
    loadWebComponent() {
      const store = useStore();

      this.$refs.calc.addEventListener('formStateChanged', (e) => {
        if (e.detail.state !== 'submitted') {
          store.state.steps[3].canAccess = false;
          store.state.steps[4].canAccess = false;
          store.dispatch('triggerReload');
          return;
        }

        store.state.steps[3].canAccess = true;
        store.state.steps[4].canAccess = true;
        store.commit("setStep", 3);
        
        const interimFormData = store.getters.formData;
        interimFormData.Size = e.detail.data.size;
        interimFormData.Type = calcData.type[e.detail.data.type];
        interimFormData.Shade = calcData.shade[e.detail.data.shade];
        interimFormData.Direction = calcData.direction[e.detail.data.direction];

        store.commit("setFormData", interimFormData);
        store.dispatch({ type: "postCustomerForm" });
        // Call make api
      });

      this.$refs.calc.addEventListener('payClick', () => {
        
        store.commit("setStep", 4);
      });

      const scriptUrl = '/voltacalc/volta-calc.js';
      if (!document.querySelector(`script[src="${scriptUrl}"]`)) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.onload = this.addWebComponent;
        document.head.appendChild(script);
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/setup";

volta-calc::part(main-container) {}
</style>